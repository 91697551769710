import React from "react";

export const ErrorPage = () => {


  return (
    <div className="contentBG1" id="aboutMain">
      <div>Woahhhhhhh Nothing to see here!</div>
    </div>
  );
}